import { Box, BoxProps, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { AnimationDefinition } from 'framer-motion/types/render/utils/animation';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useMemo, useRef } from 'react';

import SiteLink from 'components/link';
import SearchAutocomplete from 'components/search-autocomplete/search-autocomplete';
import UnderlineLink from 'components/shared/underline-link';
import VisibilitySlideFade from 'components/shared/visibility-slide-fade';
import { useLocalizedLink } from 'hooks/useLocalizedLink';
import { buildMenuItemChunks, buildMenuItemWithChunks } from 'lib/utils';
import { MenuItem as MenuItemType, MenuItemWithChunks } from 'types';
import ImageLink from './nav/image-link';
import NavDropdown from './nav/nav-dropdown';
import NavLink from './nav/nav-link';

export interface NavProps {
  menu?: Record<string, MenuItemType>;
  isSearchOpen?: boolean;
  onSearchClose?(): void;
  bg?: BoxProps['bg'];
}

export interface DropdownLinkProps {
  item: MenuItemType;
  locale: string;
  color?: string;
  bg?: BoxProps['bg'];
}

export interface DropdownContentProps {
  chunks: MenuItemType[][];
  locale: string;
  spacing?: number;
}

const perChunk = 12;

const DropdownLinks: FC<DropdownContentProps> = ({ chunks, locale, spacing }) => {
  return (
    <HStack spacing={spacing || 10} align="flex-start" maxW="full">
      {chunks.map((chunk, i) => (
        <VStack key={`chunk-${i}`} align="flex-start">
          {chunk.map((child2, i) =>
            child2.isHeader ? (
              <Text key={`level2-${i}`} as="span" layerStyle="menuHeader">
                {child2.name}
              </Text>
            ) : (
              <SiteLink
                key={`level2-${i}`}
                href={locale === 'en' ? child2.hrefEn : child2.href}
                color="darkGrayText"
                fontSize="sm"
                fontWeight="semibold"
                display="block"
                _hover={{
                  color: 'black',
                }}
              >
                {child2.name}
              </SiteLink>
            ),
          )}
        </VStack>
      ))}
    </HStack>
  );
};

const DropdownLink: FC<DropdownLinkProps> = ({ item, bg = 'white', locale }) => {
  const { t } = useTranslation('common');
  const withChildren = useMemo<MenuItemWithChunks[]>(
    () =>
      item.children
    .filter((child) => child.children?.length > 0)
    .map((child) => buildMenuItemWithChunks(child, perChunk)),
    [item],
  );
  const withoutChildrenPure = item.children.filter(
    (child) => !child.children || child.children.length === 0,
  );
  const withoutChildren = useMemo<MenuItemType[][]>(
    () => buildMenuItemChunks(withoutChildrenPure, perChunk),
    [withoutChildrenPure],
  );
  
  const alteredMenu = useMemo<MenuItemWithChunks[]>(
    () =>
      withChildren.concat([
        {
          name: t('menu.other'),
          children: withoutChildren,
        },
      ]),
    [t, withChildren, withoutChildren],
  );

  const chunks = useMemo<MenuItemType[][]>(() => buildMenuItemChunks(item.children, perChunk), [
    item,
  ]);

  return (
    <NavDropdown
      link={{ href: locale === 'en' ? item.hrefEn : item.href, name: item.name }}
      dropdown={{
        bg,
      }}
    >
      {item.noHeader ? (
        <DropdownLinks chunks={chunks} locale={locale} spacing={20} />
      ) : (
        <Stack direction="row" spacing={4} w="full" justify="space-between">
          {withChildren?.length === 0 && withoutChildrenPure?.length > 0 ? (
            <HStack spacing={4} justify="space-between" w="full">
              {withoutChildrenPure.map((child, i) => (
                <UnderlineLink
                  key={`level2-${i}`}
                  href={locale === 'en' ? child.hrefEn : child.href}
                  name={child.name}
                />
              ))}
            </HStack>
          ) : (
            alteredMenu?.map((child, i) => (
              <Box key={`level1-${i}`}>
                {child.href || child.hrefEn ? (
                  <SiteLink
                    href={locale === 'en' ? child.hrefEn : child.href}
                    layerStyle="menuHeader"
                    _hover={{
                      color: 'black',
                    }}
                  >
                    {child.name}
                  </SiteLink>
                ) : (
                  <Text as="span" layerStyle="menuHeader">
                    {child.name}
                  </Text>
                )}
                <Box mt={5}>
                  <DropdownLinks chunks={child.children} locale={locale} />
                </Box>
              </Box>
            ))
          )}
        </Stack>
      )}
    </NavDropdown>
  );
};

const Nav: React.FC<NavProps> = ({ menu, bg = 'white', isSearchOpen = false, onSearchClose }) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const localizeLink = useLocalizedLink();
  const searchRef = useRef<HTMLInputElement>();

  const handleSearchAnimationComplete = (definition: AnimationDefinition) => {
    if (definition === 'enter' && isSearchOpen && searchRef.current) {
      searchRef.current.focus();
    }
  };

  return (
    <HStack as="nav" position="relative" px={5} spacing={5} justifyContent="center">
      <NavDropdown
        link={{
          href: localizeLink('sneakers'),
          name: t('menu.sneakers.title'),
        }}
        dropdown={{
          bg,
        }}
      >
        <Stack direction="row" spacing={6}>
          <ImageLink
            href={localizeLink('sneakersMen')}
            src="/images/categories/sneakers/men.jpg"
            name={t('menu.sneakers.men')}
          />
          <ImageLink
            href={localizeLink('sneakersWomen')}
            src="/images/categories/sneakers/women.jpg"
            name={t('menu.sneakers.women')}
          />
          <ImageLink
            href={localizeLink('sneakersKids')}
            src="/images/categories/sneakers/kids.jpg"
            name={t('menu.sneakers.kids')}
          />
        </Stack>
      </NavDropdown>
      <DropdownLink item={menu.clothing} locale={router.locale} bg={bg} />
      <DropdownLink item={menu.accessories} locale={router.locale} bg={bg} />
      <NavDropdown
        link={{
          href: localizeLink('design'),
          name: t('menu.design.title'),
        }}
        dropdown={{
          bg,
        }}
      >
        <Stack direction="row" spacing={6}>
          <ImageLink
            href={localizeLink('interiorDesign')}
            src="/images/categories/design/interior-design.jpg"
            name={t('menu.design.interior')}
          />
          <ImageLink
            href={localizeLink('collectibles')}
            src="/images/categories/design/collectibles.jpg"
            name={t('menu.design.collectibles')}
          />
        </Stack>
      </NavDropdown>
      <DropdownLink item={menu.brands} locale={router.locale} bg={bg} />
      <NavLink className="chakra-link--white" href={localizeLink('vintage')}>
        {t('menu.vintage')}
      </NavLink>
      <NavLink className="chakra-link--white" href={localizeLink('news')}>
        {t('menu.news')}
      </NavLink>
      <VisibilitySlideFade
        in={isSearchOpen}
        offsetX="150px"
        offsetY="0"
        style={{ position: 'absolute', width: '100%' }}
        onAnimationComplete={handleSearchAnimationComplete}
      >
        <SearchAutocomplete ref={searchRef} onInputClose={onSearchClose} />
      </VisibilitySlideFade>
    </HStack>
  );
};

export default Nav;
